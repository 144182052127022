import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  icon?: string;
  url?: string;
  classes?: string;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}
const NavigationItems = [
  {
    id: 'inicio',
    title: 'Inicio',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'home',
        title: 'Inicio',
        type: 'item',
        classes: 'nav-item',
        url: '/home',
        icon: 'ti ti-dashboard',
        breadcrumbs: false
      }
    ]
  },
  {
    id: 'herramientas',
    title: 'Herramientas',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'diccionario-datos',
        title: 'Diccionario de Datos',
        type: 'item',
        url: '/herramientas/diccionario-datos',
        classes: 'nav-item',
        icon: 'ti ti-database-cog',
        breadcrumbs: false
      },
      {
        id: 'constructor',
        title: 'Constructor',
        type: 'item',
        url: '/herramientas/constructor',
        classes: 'nav-item',
        icon: 'ti ti-database-cog',
        breadcrumbs: false
      },
    ]
  },
  {
    id: 'operaciones',
    title: 'Operaciones',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'compras',
        title: 'Compras',
        type: 'collapse',
        icon: 'ti ti-shopping-cart',
        children: [
          {
            id: 'orden-compra',
            title: 'Orden de Compra',
            type: 'item',
            url: '/compras/orden-compra',
            classes: 'nav-item',
            icon: 'ti ti-shopping-bag'
          },
          {
            id: 'lista-precios-proveedores',
            title: 'Lista Precios Proveedores',
            type: 'item',
            url: '/compras/lista-precios-proveedores',
            classes: 'nav-item',
            icon: 'ti ti-align-box-left-stretch'
          },
          {
            id: 'radicacion-factura',
            title: 'Radicacion de Facturas Proveedor',
            type: 'item',
            url: '/compras/radicacion-factura',
            classes: 'nav-item',
            icon: 'ti ti-file-dollar'
          },
          {
            id: 'productos',
            title: 'Maestro de Productos',
            type: 'item',
            url: '/compras/productos',
            classes: 'nav-item',
            icon: 'ti ti-shopping-bag'
          },
        ]
      }
    ]
  },
];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
